import React from 'react';
import Link from '@nubank/nuds-web/components/Link/Link';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Grid from '@nubank/nuds-web/components/Grid/Grid';

import Image from '@nubank/www-latam-commons/components/Image/Image';

import { BenefitsCards } from './BenefitsCards';
import { StyledBox } from './styles/StyledBox';

// TODO: Adjust to the [Website BR Review Checklist](https://github.com/nubank/www-latam/blob/br-pull-request-checklist/sites/br/docs/pull_request_checklist.md)
function SectionBenefitsCards() {
  return (
    <Box tag="section" padding="64px 0">
      <Box
        paddingLeft={Grid.defaultProps.paddingLeft}
        marginBottom={{ xs: '24px', md: '40px' }}
      >
        <Typography
          tag="h2"
          variant="heading2"
          color="primary.default"
        >
          Facilidades
        </Typography>
      </Box>
      <StyledBox
        display="flex"
        gridTemplateColumns={`repeat(${BenefitsCards.length}, auto)`}
        paddingHorizontal={Grid.defaultProps.paddingLeft}
        gridGap={{ xs: '16px', md: '40px' }}
        flex-direction="row"
        overflow="auto hidden"
      >
        {BenefitsCards.map(item => (
          <Box
            borderRadius="26px"
            display="flex"
            flexDirection="column"
            key={`benefits-cards-${item.id}`}
            minWidth={{ xs: '288px', md: '400px' }}
            width={{ lg: '33%' }}
            overflow="hidden"
            backgroundColor="white.dark"
          >
            <Box width="100%">
              <Image
                loading="lazy"
                objectFit="cover"
                height={{ xs: '260px', md: '330px', xl: '400px' }}
                src={item.imgSrc}
                alt={item.imgAlt}
              />
            </Box>
            <Box
              padding="24px"
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography
                variant="heading4"
                tag="h4"
                color="primary.default"
                marginBottom="4x"
              >
                {item.title}
              </Typography>
              <Typography
                variant="subtitle1"
                tag="p"
                color="black.light"
                marginBottom="4x"
              >
                {item.description}
              </Typography>
              <Link
                href={item.ctaHref}
                variant="action"
                width="fit-content"
                marginTop="auto"
                typographyProps={{
                  color: 'primary.default',
                }}
              >
                {item.ctaText}
              </Link>
            </Box>
          </Box>
        ))}
      </StyledBox>
    </Box>
  );
}

export default SectionBenefitsCards;
