export const BenefitsCards = [
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713461448-carrossel-1-desk.png',
    imgAlt: 'Homem branco sentado em uma mesa de trabalho com o computador aberto, enquanto olha o seu celular',
    title: 'Cartão Virtual',
    description: 'Alternativa mais prática e segura para comprar online. Pode ser recorrente ou temporário.',
    ctaHref: 'https://blog.nubank.com.br/cartao-virtual-nubank-tudo-sobre/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__06062023',
    ctaText: 'Saiba mais',
    id: 77,
  },
  {
    imgSrc: 'https://www.datocms-assets.com/39397/1713818471-carrossel-2-tablet-1.jpg',
    imgAlt: 'imagem de uma mão segurando o cartão ultravioleta do nubank',
    title: 'Cartão Adicional',
    description:
      'Um cartão físico adicional que você pode compartilhar com outra pessoa e ambos usam o mesmo limite.',
    ctaHref: 'https://blog.nubank.com.br/cartao-de-credito-adicional-do-nubank-tudo-o-que-voce-precisa-saber/?utm_source=website&utm_medium=blog&utm_campaign=cp-portfolio&utm_content=n-possibilidades____leia&utm_term=dg__pub__brand__all__06062023',
    ctaText: 'Saiba mais',
    id: 78,
  },
  {
    imgSrc: 'https://nubank.com.br/images-cms/1713457702-2-dobra-home-desk.jpg',
    imgAlt: 'foto de uma pessoa segurando seu celular e pagando por aproximação a conta de um restaurante',
    title: 'Ferramentas de proteção',
    description:
      'No aplicativo, monitore e acione as ferramentas de segurança que podem aumentar a proteção do seu cartão.',
    ctaHref: 'https://nubank.com.br/seguranca/',
    ctaText: 'Saiba mais',
    id: 79,
  },
];
